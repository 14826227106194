.counterContainer {
    row-gap: 10px;
    column-gap: 30px;
    flex-wrap: nowrap !important;
    // justify-content: space-between;
    align-items: end;

    // .MuiGrid-grid-lg-5 {
    //     flex-grow: 0;
    //     max-width: 25%;
    //     flex-basis: 25%;
    // }
}

@media (max-width: 1440px) {
    .counterContainer {
        column-gap: 24px;
    }
}

@media (max-width: 1600px) {
    .counterContainer {
        // column-gap: 10px;
        flex-wrap: wrap !important;
    }

    .MuiGrid-grid-lg-5 {
        flex-grow: 0 !important;
        max-width: 48.666667% !important;
        flex-basis: 49.666667% !important;
    }
}

@media (max-width: 893px) {
    .MuiGrid-grid-lg-5 {
        flex-grow: 0 !important;
        max-width: 100% !important;
        flex-basis: 100% !important;
    }
}

@media (max-width: 768px) {
    .counterContainer {
        flex-wrap: wrap !important;
    }
}

.Widget__container {
    margin: 0px;
    padding: 0px;
    .Widget__mainHeading {
        color: #fff;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 500;
        position: relative;
        margin: 0px;
        height: 37px;
        -webkit-text-stroke: 0.04rem red;
    }
    .Widget__root {
        padding: 10px;
        display: flex;
        border-radius: 4px;
        column-gap: 20px;
        justify-content: space-between;
        height: 115px;
        .Widget__leftSide {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .Widget__toAchieve {
                .Widget__figure {
                    color: #fff;
                    font-weight: 500;
                    margin: 0;
                    font-size: 21px;
                    font-weight: 700;
                    transition: top ease 0.5s;
                    top: 0px;
                    position: relative;
                }
                .Widget__figureHeading {
                    color: #fff;
                    font-size: 16px;
                    font-weight: 400;
                    margin: 0;
                }
            }
        }
        .Widget__rightSide {
            display: flex;
            align-items: flex-end;
            .Widget__image {
                width: 60px;
                height: 60px;
                color: #fff;
                opacity: 0.5;
            }
        }
    }
}
