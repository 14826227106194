.Dashboard_Container {
    .dashboard_content {
        padding: 18px;
        position: absolute;
        top: 65px;
        width: 100%;
    }

    .Dashboard__item {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-thumb {
    background: #ccc;
}

::-webkit-scrollbar-track {
    background: #ccc;
}