.appBar {
    position: absolute;
    // zIndex: theme.zIndex.drawer + 1;
    // transition: theme.transitions.create([width, margin, background], {
    //   easing: theme.transitions.easing.sharp;
    //   duration: theme.transitions.duration.leavingScreen;
    // });
    background-color: #e0dcfe;
    box-shadow: none !important;
    & ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #e0dcfe;
    }
    & ::-moz-placeholder {
        /* Firefox 19+ */
        color: #e0dcfe;
    }
    & :-ms-input-placeholder {
        /* IE 10+ */
        color: #e0dcfe;
    }
    & :-moz-placeholder {
        /* Firefox 18- */
        color: #e0dcfe;
    }
    .toolbarContainer {
        .toolbar {
            .btnContainer {
                margin-left: 0px;
            }
        }
    }
}
.appBarShift {
    // transition: theme.transitions.create([width; margin; background]; {
    //     easing: theme.transitions.easing.sharp;
    //     duration: theme.transitions.duration.enteringScreen;
    //   });

    background-color: #e0dcfe;
    .toolbarContainer {
        .toolbar {
            .btnContainer {
                margin-left: 240px;
            }
        }
    }
}
.darker {
    &:after {
        content: '';
        left: -240px;
        width: calc(100% + 240px);
        position: absolute;
        bottom: -2px;
        height: 1px;
        filter: blur(3px);
    }
}
.toolbarContainer {
    flex-direction: column;
    background-color: #e0dcfe;
    min-height: 50px !important;
    justify-content: center !important;
    .toolbar {
        justify-content: space-between;
        align-items: center;
        align-content: center;
        display: flex;
        width: 100%;
        height: 40px;

        .btnContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;

            .activeMediaBtn {
                height: 70px;
                // margin-left: -17px;
            }
        }

        .menu-icon {
            width: 18px;
            height: 60px;
            padding-top: 10px;
        }

        .menu-icon:hover {
            cursor: pointer;
        }

        .search-img {
            font-size: 18px;
            margin-right: 3px;
        }

        .search-btn {
            color: #fff !important;
            background-color: #7904d7 !important;
            border-radius: 10px !important;
            text-transform: capitalize;
            font-size: 13px;
            padding-left: 7px;
        }

        .MuiPickersToolbarText-toolbarTxt {
            color: red !important;
        }

        .search-icon {
            margin: 0px 5px 0px 0px;
        }

        .AS-logo-container {
            cursor: pointer;
            padding: 0px 10px 0px 13px;
        }

        .AS-logo {
            // width: 31px;
            height: 40px;
            mix-blend-mode: multiply;
        }

        .calender-icon {
            color: #c7c7c7;
            font-size: 13px;
        }
    }
}
.dateContainer {
    display: flex;
    column-gap: 10px;
    align-items: center;
    padding: 10px;
    .date {
        background-color: #fff;
        border-radius: 5px;

        & .MuiInputBase-input {
            color: #fff !important;
        }

        & .MuiInputLabel-formControl-3814 {
            color: #fff !important;
        }

        & .MuiInput-underline {
            border: 0;
        }

        & .MuiInput-underline:before {
            border: 0;
        }

        & .MuiInput-underline:hover {
            border: 0;
            outline: none !important;
        }

        & .MuiInput-underline:hover:not(.Mui-disabled):before {
            border: 0;
        }

        & .MuiInput-underline:after {
            border: 0;
        }

        & .MuiInputBase-input {
            color: #b3b3b3 !important;
            padding: 0.7rem;
            font-size: 13px;
        }
    }
}
.nationalLogo {
    color: #fff;
    // width: 40px;
    // height: 35px;
    // filter: grayscale(1);
}

.UserMenu__container {
    display: flex;
    align-items: center;
}

@media (max-width: 768px) {
    .nationalLogo {
        width: 130px;
        height: 55px;
    }
    .date {
        margin-top: -8px;
        margin-bottom: 10px;
    }
    .search-btn {
        color: #fff !important;
        background-color: #7904d7 !important;
        border-radius: 10px !important;
        text-transform: capitalize !important;
        font-size: 13px !important;
        padding-left: 7px !important;
    }
    .AS-logo {
        margin: 10px 5px 0px 0px;
    }

    .calender-icon {
        color: #c7c7c7;
        font-size: 13px !important;
    }
}

@media (max-width: 1200px) {
    .appBarShift {
        .toolbarContainer {
            .toolbar {
                .btnContainer {
                    margin-left: 0px !important;
                }
            }
        }
    }
}

@media (min-width: 768px) {
    .date {
        margin-top: 15px;
    }
    .activeMediaBtn {
        margin-top: 10px;
    }
}

@media (min-width: 992px) {
    .appBarShift {
        margin-left: 240px;
        width: calc(100% - 240px);
    }
    // .toolbarContainer {
    //   .toolbar {
    //     .btnContainer {
    //       margin-left: 0px !important;
    //     }
    //   }
    // }
}

@media screen and (max-width: 1024px) {
    .menu-icon {
        padding: 5px 20px;
    }
}

@media screen and (max-width: 425px) {
    .menu-icon {
        padding: 5px 15px;
    }

    .dateContainer {
        display: flex;
        flex-direction: column;
        padding-top: 20px;
    }

    .date {
        width: 90vw !important;
        margin: 5px 0px !important;
    }

    .AS-logo {
        margin: 5px 5px 0px 0px;
    }

    .logo-container {
        // width: 75vw;
        display: flex;
        justify-content: center !important;
        align-items: center;
    }

    .activeMediaBtn {
        align-self: center;
    }

    .toolbarContainer {
        padding: 20px 0px;
    }

    @media screen and (max-width: 375px) {
        .logo-container {
            width: 12vw;
            display: flex;
            justify-content: center !important;
            align-items: center;
        }
    }

    @media screen and (max-width: 320px) {
        .logo-container {
            width: 14vw;
            display: flex;
            justify-content: center !important;
            align-items: center;
        }
    }
}
