@import '../../styles/mixins';

.allStats {
    display: flex;
    column-gap: 10px;
    align-self: center;
    height: 60%;
    align-items: center;
    justify-content: space-around;
    // flex-wrap: wrap;
    .statsContainer {
        display: flex;
        column-gap: 20px;
        width: 215px;
        height: 75px;
        row-gap: 10px;
        margin: 10px;
        align-items: center;
        .imageContainer {
            width: 60px;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
            background: material-color('blue', '300');
            border-radius: 50%;
            background-color: #e0dcfe;
            .image {
                color: #7904d7;
                width: 40px;
                height: 40px;
            }
        }
        .textContainer {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            margin: 0;
            padding: 0;
            padding-bottom: 2px;
            .stat {
                margin: 0;
                font-size: 42px;
                // font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
                font-family: 'Poppins';
                font-weight: 600;
                // line-height: 1.6;
                letter-spacing: 0.0075em;
                transition: top ease 0.5s;
                top: 3px;
                position: relative;
                color: #7904d7;
            }
            .Heading {
                font-size: 1rem;
                // font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
                font-family: 'Poppins';
                font-weight: 400;
                line-height: 1.5;
                letter-spacing: 0.00938e;
                margin: 0;
                color: #454f5b;
            }
            .salesContainer {
                display: flex;
            }
            .trending-icon {
                margin-left: 2px;
                color: #f59e0b;
            }
        }
    }
}

@media (max-width: 425px) {
    .allStats {
        flex-direction: column;
    }
}
