.LoginForm__formWrap {
  padding: 0px;
  position: relative;
  .LoginForm__brandImage {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0.2;
    object-fit: contain;
    mix-blend-mode: multiply;
  }
  .LoginForm__formContainer {
    padding: 20px;
    .LoginForm__field {
      width: 100%;
      margin: 10px 0px;
    }
    .LoginForm__mainHeading {
      color: #000;
    }
    .LoginForm__formControl {
      width: 100%;
      margin: 10px 0px;
    }
    .LoginForm__btnArea {
      justify-content: "flex-end";
      display: "flex";
      align-items: "center";
      margin-bottom: 20px;
      text-align: end;
      margin-top: 20px;
      .LoginForm__rightIcon {
        margin-left: 10px;
      }
    }
    .form_loader{
      color: white;
    }
  }
}
@media (min-width: 768px) {
  .LoginForm__loginWrap {
    width: 860px;
  }
}

@media (max-width: 576px) {
  .LoginForm__btnArea {
    flex-direction: "column";
    &:button {
      width: "100%";
      margin: 5;
    }
  }
}
