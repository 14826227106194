.variable-width .slick-slide p {
  background: blue;
  height: 100px;
  color: #fff;
  margin: 5px;
  line-height: 100px;
  text-align: center;
}
.center .slick-center h3 {
  color: #e67e22;
  opacity: 1;
  transform: scale(1.08);
}
.center h3 {
  opacity: 0.8;
  transition: all 300ms ease;
}
.content {
  padding: 20px;
  margin: auto;
  width: 90%;
}
.slick-slide {
  transition: transform .3s
}
.slick-slide .image {
  padding: 10px;
}
.slick-slide img {
  border: 5px solid #fff;
  display: block;
  margin: auto;
}
.slick-slide img.slick-loading {
  border: 0;
}
.slick-slider {
  margin: 0 auto 10px;
}
.slick-dots {
  margin-left: 0;
}
.slick-thumb {
  bottom: -45px;
}
.slick-thumb li {
  width: 60px;
  height: 45px;
}
.slick-thumb li img {
  filter: grayscale(100%);
}
.slick-thumb li.slick-active img {
  filter: grayscale(0%);
}
@media (max-width: 768px) {
  h3 {
    font-size: 24px;
  }
  .center {
    margin-left: -40px;
    margin-right: -40px;
  }
  .center .slick-center h3 {
    color: #e67e22;
    opacity: 1;
    transform: scale(1);
  }
  .center h3 {
    opacity: 0.8;
    transform: scale(0.95);
    transition: all 300ms ease;
  }
}
.slick-vertical .slick-slide {
  height: 180px;
}
button.slick-arrow {
  background-color: grey !important;
  width: 40px;
  height: 40px;
  padding-top: 5px;
  z-index: 10;
}
button.slick-arrow:before{
  font-size: 36px;
}
button.slick-arrow:hover,
button.slick-arrow:focus{
  background-color: grey;
}
.slick-center{
  transform: scale(1.2);
}
.thumb-nav .slick-dots {
  margin: 20px 0;
  bottom: -60px;
}
.thumb-nav .slick-dots li{
  width: 60px;
  height: auto;
  max-width: none;
}
.thumb-nav .slick-dots li.slick-active{
  border-bottom: 2px solid grey;
}
.custom-arrow .nav-prev,
.custom-arrow .nav-next{
  position: absolute;
  bottom: -40px;
  z-index: 10;
}
.custom-arrow .nav-prev{
  left: 0
}
.custom-arrow .nav-next{
  right: 0
}
