.ErrorBoundryScreen-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #bbbb;
}

.ErrorBoundryScreen-error-image {
    width: 500px;
    height: 250px;
}

.ErrorBoundryScreen-text-1 {
    text-align: center;
    font-size: 17px;
}

.ErrorBoundryScreen-text-2 {
    text-align: center;
    font-size: 20px;
}
