.appFrameOuter {
  min-height: 100%;
  position: relative;
  display: flex;
  width: 100%;
  .outerContent {
    background: url("../../../../assets/images/material_bg.svg") no-repeat
      gainsboro left bottom;
    width: 100%;
    background-size: cover;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;

    .brand {
      display: flex;
      padding: 10px 10px 5px;
      position: relative;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: 15px 0px;
      align-content: center;

      & h3 {
        margin: 0;
        font-size: 16;
        font-weight: 500;
        padding-left: 10;
        // color: theme.palette.common.white;
      }
      // .brandText {
      // }
    }
  }
}

@media (max-width: 768px) {
  .appFrameOuter {
    .outerContent {
      padding: 20px 0;
    }
  }
}
