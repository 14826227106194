.TwoSideWidget__container {
    margin: 0px;
    padding: 0px;
    width: 100%;
    .TwoSideWidget__mainHeading {
        font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
        font-size: 18px;
        text-transform: uppercase;
        color: #000;
        // font-weight: 800;
        position: relative;
        margin: 0px;
        // -webkit-text-stroke: 0.03rem ;
    }
    .TwoSideWidget__root {
        padding: 30px;
        display: flex;
        border-radius: 8px;
        column-gap: 20px;
        justify-content: center;
        height: 157px;
        box-shadow: 0px 0px 5px #b3b3b3;
        .TwoSideWidget__leftSide {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .to_Achieve_container {
                display: flex !important;
                align-items: center;
            }

            .TwoSideWidget__toAchieve {
                .TwoSideWidget__figure {
                    color: #000;
                    margin: 0;
                    font-weight: 300;
                    font-size: 21px;
                    transition: top ease 0.5s;
                    top: 0px;
                    padding-right: 5px;
                    font-family: 'Poppins';
                    text-transform: capitalize;
                    position: relative;
                }
                .TwoSideWidget_Tooltip {
                    display: flex;
                    .tooltip-icon {
                        font-size: 11px;
                        color: #7904d7 !important;
                    }
                }
                .achieved_count {
                    font-size: 42px;
                    font-weight: 400 !important;
                }

                .TwoSideWidget__figureHeading {
                    color: #637381;
                    font-size: 16px;
                    font-weight: 300;
                    margin: 0;
                    font-family: 'Poppins';
                }
            }
        }
        .TwoSideWidget__rightSide {
            display: flex;
            .icon_container {
                width: 20px;
                height: 20px;
                padding: 7px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #e0dcfe;
                border-radius: 8px;
                .TwoSideWidget__image {
                    color: #7904d7;
                    font-size: 23px;
                    // opacity: 0.5;
                }
            }
        }
    }
}
