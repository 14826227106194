.PaymentCollection_Container {
    .PaymentCollection_Main_Container {
        padding: 18px;
        position: absolute;
        top: 65px;
        width: 100%;
    }

    .PaymentCollection_Card {
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
            0px 1px 3px 0px rgba(0, 0, 0, 0.12);
        height: auto;
        margin-right: 2.3245rem;
        padding: 15px 15px;
    }

    .PaymentCollection_Card_Content {
        background-color: #fafafa;
        padding: 5px 10px 10px 0px;
    }

    .Slip_Heading {
        font-family: Poppins;
        font-weight: 500;
        font-size: 26px;
        letter-spacing: -2%;
        padding: 0;
        margin: 0;
        padding-left: 15px;
    }

    .PaymentCollection_Card_EndItem {
        display: flex;
        justify-content: flex-end;
        padding: 0 !important;
    }

    .PaymentCollection_Grid_Container {
        padding: 10px 25px 10px 25px;
    }

    p {
        margin: 0;
    }

    .PaymentCollection_paddingTop {
        padding-top: 5px !important;
    }

    .PaymentCollection_paddingTop_max {
        padding-top: 25px !important;
    }

    .PaymentCollection_Status {
        margin-top: 20px !important;
    }

    .PaymentCollection_Status p {
        font-size: 20px !important;
        font-family: Poppins !important;
    }

    .PaymentCollection_Card_Box_Left_Text {
        font-family: Poppins;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
    }

    .PaymentCollection_Card_Box_Left_Last_Text {
        font-family: Poppins;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #b3b3b3;
    }

    .PaymentCollection_Card_Box_Right_Text {
        font-family: Poppins;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #b3b3b3;
    }

    .PaymentCollection_Card_EndItem_Button {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
    }

    .PaymentCollection_Card_Generate_Button {
        display: flex;
        justify-content: center;
        margin-top: 30px;
    }

    .PaymentCollection_NotFound_Container{
        height: 50vh;
        min-height: 50vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width: 425px) {
    .PaymentCollection_Container {
        .PaymentCollection_Grid_Container {
            padding: 10px 10px 10px 10px;
        }

        .PaymentCollection_Card_EndItem_Button_Two {
        }
    }
}

@media screen and (max-width: 894px) {
    .PaymentCollection_Container {
        .PaymentCollection_Card_EndItem_Button_Two {
            margin-top: 10px;
        }
    }
}
