.CattleMandiTables_Container{
    .CattleMandiTables_content{
        padding: 16px;
        position: relative;
        top: -10rem;
    }
    
    .CattleMandiTables_Item {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}