.OneSideWidget__container {
  margin: 0px;
  padding: 0px;
  .OneSideWidget__mainHeading {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 18px;
    text-transform: uppercase;
    color: #000;
    // font-weight: 800;
    position: relative;
    margin: 0px;
    &:hover {
      font-size: 23px;
    }
    // -webkit-text-stroke: 0.03rem ;
  }
  .OneSideWidget__root {
    padding: 10px;
    display: flex;
    border-radius: 4px;
    column-gap: 20px;
    height: 115px;
    justify-content: space-around !important;
    align-items: center;
    .OneSideWidget__leftSide {
      .OneSideWidget__figure {
        color: #fff;
        font-weight: 500;
        margin: 0;
        font-size: 21px;
        font-weight: 700;
        transition: top ease 0.5s;
        top: 0px;
        position: relative;
        &:hover {
          top: -10px;
          font-size: 30px;
        }
      }
      .OneSideWidget__figureHeading {
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        margin: 0;
      }
    }

    .OneSideWidget__image {
      width: 60px;
      height: 60px;
      color: #fff;
      opacity: 0.5;
    }
  }
}
