.root {
  display: flex;
  width: 100%;
  z-index: 1;
  position: relative;

  .container {
    display: flex;
    align-items: center;
    width: 100%;

    .loginWrap {
      .welcomeWrap {
        position: relative;
        overflow: hidden;
        .welcome {
          position: absolute;
          width: 100%;
          height: calc(100% + 50px);
          padding: 20px 0px;
          display: flex;
          align-items: center;
          overflow-x: hidden;
          overflow-y: clip;
          justify-content: start;
          align-items: center;
          align-content: center;
          // right: -33px;
          top: -25px;
          .brandImage {
            position: absolute;
            opacity: 0.3;
            width: 100%;
            height: 100%;
            padding: 0;
            margin: 0;
            left: 0;
            right: 0;
          }
          .welcomeContent {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .brand {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              position: relative;
              margin-bottom: 20;
              img {
                height: 130px;
              }
              h3 {
                font-size: 30px;
                margin: 0;
                padding-left: 10;
                font-weight: 500;
                color: #fff;
              }
            }
            .brandText {
              margin-top: 10px;
              color: #000;
              position: relative;
              text-align: center;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .container {
    overflow: hidden;
    padding: 0px 110px 0px 40px;
    .loginWrap {
      width: 860px;
    }

  }
}

@media (min-width: 320px) {
  .brandText {
    margin-top: 30px;
  }
}