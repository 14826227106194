.EntryGateMarshalling_Container {
    .MuiOutlinedInput-input {
        color: #000;
    }
    .EntryGateMarshalling_Form {
        padding: 18px;
        position: absolute;
        top: 65px;
        width: 100%;

        .EntryGateMarshalling_Card {
            box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
                0px 1px 3px 0px rgba(0, 0, 0, 0.12);

            height: auto;
            margin-right: 2.3245rem;
        }
        form {
            .TextInput_Container {
                padding: 30px;
                .TextInput_Main {
                    padding: 20px 0;
                    width: 99%;
                    label {
                        font-family: Poppins;

                        font-weight: 600;
                    }
                    .TextInput {
                        width: 100%;

                        input::-webkit-outer-spin-button,
                        input::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }

                        input[type='number'] {
                            -moz-appearance: textfield;
                        }
                    }
                    .error {
                        font-family: 'Poppins';
                        color: red;
                    }

                    .EntryGateMarshalling_Camera_Box {
                        border: 1px dashed #b3b3b3;
                        background-color: #f3f3f3;
                        // padding: 3rem 0rem;
                        height: 174px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        user-select: none;
                    }

                    .EntryGateMarshalling_Click {
                        color: #b3b3b3;
                        font-size: 20px;
                    }

                    .EntryGateMarshalling_Camera_Icon {
                        color: #b3b3b3;
                        margin-bottom: 6px;
                        font-size: 40px;
                    }

                    .EntryGateMarshalling_Camera_Image {
                        width: 120px;
                        height: 120px;
                        border-radius: 2px;
                    }
                }

                .Btn_Container {
                    width: 100%;
                }

                .Btn_Container_Two {
                    padding-top: 20px;
                    display: flex;
                    justify-content: flex-end;
                }

                .EntryGateMarshalling_Form_Two_Container {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                }

                p {
                    margin: 0;
                    padding: 0;
                    font-family: Poppins;
                    font-weight: 600;
                    font-size: 26px;
                    line-height: 30px;
                }
            }
        }
    }

    .react-html5-camera-photo > img,
    .react-html5-camera-photo > video {
        width: 100% !important;
        height: 100%;
    }
}

@media screen and (max-width: 959px) {
    .EntryGateMarshalling_Container {
        .EntryGateMarshalling_Form {
            form {
                .TextInput_Container {
                    .TextInput_Main {
                        width: 100%;
                    }

                    .EntryGateMarshalling_Form_Two_Container {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .EntryGateMarshalling_Container {
        .react-html5-camera-photo > img,
        .react-html5-camera-photo > video {
            width: 100% !important;
            height: 100% !important;
            padding-top: 191px !important;
        }
    }
}
