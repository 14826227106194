.MuiAlert-root {
    font-weight: 500;
    width: 25rem;
    .MuiName {
        font-weight: 600;
        font-family: Georgia, 'Times New Roman', Times, serif;
    }
}

@media (max-width: 430px) {
    .MuiAlert-root {
        width: 17rem;
    }
}
