.CattleMandiHome_Container {
    .CattleMandiHome_Main_Container {
        padding: 18px;
        position: absolute;
        top: 65px;
        width: 100%;
    }

    .CattleMandiHome_Card {
        margin-right: 2rem;
        height: 50vh;
        min-height: 50vh;
    }

    video {
        width: 100%;
        height: 50vh;
        min-height: 50vh;
    }

    p {
        padding: 0 !important;
        margin: 0 !important;
    }
}
