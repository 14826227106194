.DataEntry_Container {
    .DataEntry_Content_Container {
        padding: 18px;
        position: absolute;
        top: 65px;
        width: 100%;
    }

    .DataEntry_Content_card {
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
            0px 1px 3px 0px rgba(0, 0, 0, 0.12);
        height: auto;
        margin-right: 2.3245rem;
        background-color: white;
        border-radius: 8px;
        padding: 50px 50px !important;
    }

    .TextInput {
        width: 100% !important;
        color: #181717 !important;
        font-family: Poppins;
        font-weight: 400;
        font-size: 13px;
        margin-top: 5px !important;
        line-height: 19.5px;
    }

    .TextInput_Main {
        width: 100%;
    }

    label {
        font-family: Poppins;
        font-weight: 600;
    }

    .DataEntry_Input_Container {
        margin-top: 20px;
    }

    .submit-btn {
        padding-top: 20px;
        display: flex;
        justify-content: flex-end;
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .error {
        font-family: 'Poppins';
        color: red;
    }

    .dropDown_Row {
        display: flex;
        margin-right: 2px !important;
    }
}

@media screen and (max-width: 595px) {
    .DataEntry_Container {
        .DataEntry_Content_card {
            padding: 20px 20px !important;
        }
    }
}
