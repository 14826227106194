/* ImagePreview.scss */

.image-preview-container {
    position: relative;
    cursor: pointer;
    .image-preview-before {
        height: 40px;
        object-fit: contain;
    }
    .image-preview {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 999;
        max-width: 80%;
        max-height: 80%;
        border: 1px solid #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);

        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
        }
    }

    &.show .image-preview {
        display: block !important;
    }
}
