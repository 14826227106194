.Button_Group {
    button {
        width: 100%;
        text-transform: capitalize;
        .MuiButton-label {
            display: flex;
            gap: 10px;
        }
    }
}
