.PaperBlock__root {
    padding: 24px;
    // height: 90%;
    overflow: auto;

    .PaperBlock__title-container {
        display: flex;
        .PaperBlock__title {
            margin-bottom: 10px;
            padding-bottom: 5px;
            position: relative;
            // text-transform: capitalize;
            font-size: 22px;
            font-weight: 600;
            font-family: 'Poppins';
        }
        .help-icon {
            color: #7904d7 !important;
            font-size: 25px;
            font-family: 'Poppins';
        }
    }
    .PaperBlock__description {
        max-width: 960px;
        font-size: 16px;
        font-family: 'Poppins';
    }
    .PaperBlock__content {
        background-color: #fff;
        // padding: 9px;
        margin-bottom: 5px;
    }
}

@media (max-width: 425px) {
    .PaperBlock__root {
        .PaperBlock__title-container {
            justify-content: center;
        }
    }
}
