.LegendContent-container{
    display: flex;
    justify-content: center;
    padding-top: 5px;

    .LegendContent-main{
        display: flex;
        width: 340px;
        justify-content: space-around;
    }

    .LegendContent-Heading{
        color: #222B45;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
    }

    .LegendContent-content-container{
        display: flex;
        align-items: center;
    }

    .LegendContent-rounded{
        width: 25.11px;
        height: 12.6px;
        border-radius: 10px;
        margin-right: 15px;
    }
}