.LandBooker_Container {
    .LandBooker_Content {
        padding: 18px;
        position: absolute;
        top: 65px;
        width: 100%;
    }

    .LandBooker_Card {
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
            0px 1px 3px 0px rgba(0, 0, 0, 0.12);
        height: auto;
        margin-right: 2.3245rem;
        background-color: #fff;
        padding: 50px 50px;
        border-radius: 8px;
    }

    .TextInput {
        width: 100% !important;
        color: #181717 !important;
        font-family: Poppins;
        font-weight: 400;
        font-size: 13px;
        margin-top: 5px !important;
        line-height: 19.5px;
    }

    .TextInput_Main {
        width: 100%;
    }

    .Btn_Container_Two {
        padding-top: 35px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .error {
        font-family: 'Poppins';
        color: red;
    }

    label {
        font-family: 'Poppins';
    }
}
