.mainHeading {
    font-size: 50px;
    color: #000;
    margin-bottom: 2em;
}

.helpAndSupport_Content {
    position: relative;
    top: auto;
    right: auto;
    bottom: 150px;
    left: auto;
    height: 40vh;
    max-height: 232px;
    min-height: 232px;
    padding-top: 20px;
}

.helpAndSupport_Content_Container {
    padding: 20px;
}

.text {
    padding-left: 10px;
    font-family: 'Poppins';
}

@media (max-width: 1064px) {
    .mainHeading {
        font-size: 34px;
        color: #fff;
    }
}
