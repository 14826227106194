// @import "../../styles/mixins/";

.Carousels__image {
    width: 279px !important;
    height: 195px !important;
}
.slick-slide {
    img {
        display: flex !important;
        justify-content: center;
        object-fit: contain;
    }
}
@media only screen and (max-width: 959px) {
    .slick-dots {
        flex-wrap: wrap;
        height: 5vh;
        align-items: end;
        min-height: 27px;
    }
    .slick-slide {
        img {
            width: 100% !important;
        }
    }
}
