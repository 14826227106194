.multi-lands{
    font-family: "Poppins";
    margin-top: 1rem;
    gap: 5px;
    display: flex;
    flex-wrap: wrap;
    .multi-land-span{
        font-family: 'Poppins';
        background: gainsboro;
        padding: 7px;
        border-radius: 14px;
        width: 33%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 4px;
        .land-span-close{
            cursor: pointer;
            height: 18px;
        }
    }
}