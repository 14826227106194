.Retailer__root {
    .Tables__mainHeading_container {
        .Tables__mainHeading {
            background-color: #7904d7;
            height: 150px;
            border-radius: 5px;
            padding: 30px 20px;
            box-shadow: 4px 2px 6px #000000;
            color: #fff;
            position: relative;
            font-size: 24px;
            font-family: 'Poppins';
        }
    }
    .Table__loader {
        height: 200px;
        margin-top: 2%;
        margin-left: 45%;
    }
    .Retailer__content {
        padding: 16px;
        position: relative;
        top: -10rem;
        // position: absolute;
        // top: 60px;
        // right: auto;
        // bottom: auto;
        // left: auto;
        // width: 97.6%;
    }

    .select_text {
        color: rgba(0, 0, 0, 0.87) !important;
        font-weight: 500 !important;
        font-family: 'Poppins';
    }
}

.download-icon {
    width: 35px !important;
    height: 35px !important;
    cursor: pointer;
}

.Table__item {
    margin-top: 20px;
    margin-bottom: 20px;
}

.pagination_container {
    display: flex;
    align-items: center;
    padding: 20px;
}

.pagination {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin: 0px 0px 0px auto;
}

.pagination_dropdown {
    padding: 0px 0px 0px 15px;
}

@media screen and (max-width: 425px) {
    .pagination_container {
        display: block;
        align-items: center;
        padding: 20px 10px;
    }

    .pagination {
        display: block;
        padding: 20px 0px;
    }

    .pagination_dropdown {
        padding: 0px 5px;
    }

    @media screen and (max-width: 425px) {
        .pagination {
            display: flex;
        }

        .Retailer__root {
            .Tables__mainHeading_container {
                margin-top: 242px;
                width: 90%;
                display: flex;
                justify-content: center;
            }
        }
    }
}
