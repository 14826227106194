.land_modal_main {
    font-family: 'Poppins';
    .land_modal_close {
        position: absolute;
        right: 10px;
        .land_modal_close_icon {
            cursor: pointer;
        }
    }
    .land_modal_content {
        h2 {
            font-family: 'Poppins';
        }
        .MuiGrid-container {
            .MuiGrid-item {
                .land_modal_content_box {
                    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                    padding: 10px;
                    border-radius: 10px;
                    cursor: pointer;
                    p {
                        font-family: 'Poppins';
                        font-size: 14px;
                        span {
                            font-weight: 700;
                        }
                    }
                }
                .land_modal_content_box:hover {
                    background-color: #e6e6e6;
                }
                .selected {
                    background-color: #d4d4d4;
                }
            }
        }
    }
}
.land_modal_main::-webkit-scrollbar {
    width: 8px;
}

.land_modal_main::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 12px;
}
