.mainPageComponents {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
        width: 100px;
    }
}
